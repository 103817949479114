import axios from "@/utils/axios";
import { getQueryString, getQueryStringWithSameKeys } from "@/utils/helper";

const loadFilterValue = () => {
  const filterValue = JSON.parse(localStorage.getItem("filterValue"));
  return filterValue
    ? filterValue
    : {
        filter: false,
        brand_name: "",
        office_city: "",
      };
};
const state = {
  filterValue: loadFilterValue(),
  brandContact: [],
  search: "",
  searchLoading: false,
  startSearch: false,
  closeSearchBar: false,
};

const getters = {
  getAllBrandContact: (state) => state.brandContact,
  getFilterValue: (state) => {
    return state.filterValue;
  },
};

const mutations = {
  setFilterValue: (state, payload) => {
    state.filterValue = payload;
    localStorage.setItem("filterValue", JSON.stringify(payload));
  },
  doNothing: (state) => state,
  setAllBrandContact: (state, payload) => (state.brandContact = payload),
  addToBrandContact: (state, payload) => state.brandContact.unshift(payload),
  updateToBrandContact: (state, updatedData) => {
    state.brandContact.forEach((user) => {
      if (user.id == updatedData.id) {
        user.full_name = updatedData.full_name;
        user.email = updatedData.email;
        user.mobile = updatedData.mobile;
        user.street = updatedData.street;
        user.city = updatedData.city;
        user.state = updatedData.state;
        user.country = updatedData.country;
        user.pincode = updatedData.pincode;
        user.disabled = updatedData.disabled;
        user.visible = updatedData.visible;
        user.image = updatedData.image;
      }
    });
  },
  removeBrandContact: (state, id) => {
    state.brandContact = state.brandContact.filter((u) => u.id !== id);
  },
  setSearch(state, value) {
    state.search = value;
  },
  setSearchLoading(state, value) {
    state.searchLoading = value;
  },
  setStartSearch(state, value) {
    state.startSearch = value;
  },
  setCloseSearchBar(state, value) {
    state.closeSearchBar = value;
  },
};

const actions = {
  updateFilterValue: ({ commit }, filterValue) => {
    commit("setFilterValue", filterValue);
  },

  updateSearch({ commit }, value) {
    commit("setSearch", value);
  },
  updateSearchLoading({ commit }, value) {
    commit("setSearchLoading", value);
  },
  updateStartSearch({ commit }, value) {
    commit("setStartSearch", value);
  },
  updateCloseSearchBar({ commit }, value) {
    commit("setCloseSearchBar", value);
  },

  /**
   * @description Get All User
   * @param {Number} data - brandContact Object
   * @returns Promise
   */

  async fetchAllBrandContacts({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options?.all) {
        params = options;
      } else {
        const { sortBy="", sortDesc="", page=1, itemsPerPage=10 } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);
      axios
        .get(`/v1/brand_contacts?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllBrandContact", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Update User Profile
   * @param {Object} data - Update Profile Data Object
   * @returns Promise
   */
  updateBrandContact({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/brand_contacts/${data._id}`, data)
        .then((res) => {
          if (res.data.success) {
            commit("updateToBrandContact", data);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * @description Search User
   * @param {Number} data - User Object
   * @returns Promise
   */

  searchBrandContact({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        let text = options.filter;
        params = { text, all: true };
      } else {
        const { page, itemsPerPage } = options;
        params = {
          page: page,
          per_page: itemsPerPage,
        };
        let text = options.filter;
        params = { text, ...params };
      }

      let url = getQueryString(params).substring(1);

      axios
        .get(`/v1/brand_contacts/search?${url}`)
        .then((res) => {
          if (res.data.success) {
            commit("setAllBrandContact", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Remove User
   * @param {Object} data - Remove User
   * @returns Promise
   */
  removeBrandContactById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/v1/brand_contacts/${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("removeBrandContact", id);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Get User Info by ID
   * @param {Number} id - User ID
   * @returns Promise
   */
  fetchBrandContactByBrandID({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);
      let id = options._id;
      axios
        .get(`/v1/brand_contacts/brand/${id}?${url}`)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Create New User by SUPER ADMIN
   * @param {Object} data - User data Object
   * @returns Promise
   */
  createBrandContact({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/v1/brand_contacts`, data)
        .then((res) => {
          if (res.data.success) {
            commit("addToBrandContact", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          debugger
          reject(e);
        });
    });
  },

  /**
   * @description Filter Brand Contact
   * @param {Number} id - brand name, city
   * @returns Promise
   */
  async filterBrandContacts({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = {
          ...options,
          brand_name: state.filterValue.brand_name,
          office_city: state.filterValue.office_city,
        };
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
          brand_name: state.filterValue.brand_name,
          office_city: state.filterValue.office_city,
        };
      }
      let url = getQueryString(params).substring(1);
      let id = options?.id;
      let urlString = id ? `/v1/brand_contacts/filter/${id}?${url}` : `/v1/brand_contacts/filter?${url}`;
      axios
        .get(urlString)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllBrandContact", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description On download button get data in excel sheet
   * @returns Promise
   */
  async getAllExcelSheetByDownload({ commit }) {
    return new Promise((resolve, reject) => {
      const params = {
          brand_name: state.filterValue.brand_name,
          office_city: state.filterValue.office_city,
        };
      let url = getQueryStringWithSameKeys(params).substring(1);
      axios
        .get(`/v1/brand_contacts/download-excel?${url}`)
        .then((res) => {
          resolve(res);
          commit("doNothing");
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
