import { apiPath } from "@/config/apiPath";
import axios from "@/utils/axios";
import { getQueryString } from "@/utils/helper";

const loadFilterValue = () => {
  const filterValue = JSON.parse(localStorage.getItem("filterValue"));
  return filterValue
    ? filterValue
    : {
      filter: false,
      brand_name: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      req_type: "",
      priorities: "",
      timeline: "",
    };
};

const state = {
  filterValue: loadFilterValue(),
  brandRequirements: [],
  allmatchingProperties: [],
  search: "",
  searchLoading: false,
  startSearch: false,
  closeSearchBar: false,
  templates: {}
};
const getters = {
  getAllBrandRequirements: (state) => state.brandRequirements,
  getFilterValue: (state) => {
    return state.filterValue;
  },
};

const mutations = {
  setFilterValue: (state, payload) => {
    state.filterValue = payload;
    localStorage.setItem("filterValue", JSON.stringify(payload));
  },
  setAllBrandRequirement: (state, brand_requirement) =>
    (state.brandRequirements = brand_requirement),
  setAllMatchingProperties: (state, allmatchingProperties) =>
    (state.allMatchedProperties = allmatchingProperties),
  doNothing: (state) => state,
  addNewBrandRequirement: (state, brand_requirement) =>
    state.brandRequirements.unshift(brand_requirement),
  addNewMatchingProperty: (state, matchingProperty) =>
    state.allmatchingProperties.unshift(matchingProperty),
  updateBrandRequirement: (state, updatedBrandRequirement) => {
    state.brandRequirements.forEach((brand_requirement) => {
      if (brand_requirement.id == updatedBrandRequirement.id) {
        // Update Data
        (brand_requirement.brand = updatedBrandRequirement.brand),
          (brand_requirement.brand_contact =
            updatedBrandRequirement.brand_contact),
          (brand_requirement.pincode = updatedBrandRequirement.pincode),
          (brand_requirement.areas = updatedBrandRequirement.areas),
          (brand_requirement.plinth_height =
            updatedBrandRequirement.plinth_height),
          (brand_requirement.carpet_area = updatedBrandRequirement.carpet_area),
          (brand_requirement.super_area = updatedBrandRequirement.super_area),
          (brand_requirement.req_type = updatedBrandRequirement.req_type);
      }
    });
  },
  removeBrandRequirement: (state, id) => {
    state.brandRequirements = state.brandRequirements.filter(
      (brandRequirement) => brandRequirement.id !== id
    );
  },
  setTemplate: (state, data) => {
    state.templates = data
  }
};

const actions = {
  updateFilterValue: ({ commit }, filterValue) => {
    commit("setFilterValue", filterValue);
  },
  /**
   * @description Get All BRAND_REQUIREMENT
   * @param {Object} data - BRAND_REQUIREMENT Object
   * @returns Promise
   */
  async fetchAllBrandRequirements({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy='', sortDesc=[], page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.brand_requirements.getAllBrandRequirements+`?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllBrandRequirement", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Create New Brand Requiremnt
   * @param {Object} data - Brand Requiremnt Object
   * @returns Promise
   */
  createBrandRequirement({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.brand_requirements.createBrandRequirement, data)
        .then((res) => {
          if (res.data.success) {
            commit("addNewBrandRequirement", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  
  /**
   * @description Search User
   * @param {Number} data - User Object
   * @returns Promise
   */

  searchBrandRequirement({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        let name = options.filter;
        params = { name, all: true };
      } else {
        const { page, itemsPerPage } = options;
        params = {
          page: page,
          per_page: itemsPerPage,
        };
        let name = options.filter;
        params = { name, ...params };
      }

      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.brand_requirements.searchBrandRequirements+`?${url}`)
        .then((res) => {
          if (res.data.success) {
            commit("setAllBrandRequirement", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description fetch Brand Requirement by ID
   * @param {Number} id - Brand Requirement Id
   * @returns Promise
   */
  fetchBrandRequirementById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.brand_requirements.getBrandRequirementByID+`${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Update Brand Requiremnt Info
   * @param {Object} data - Update Brand Requiremnt Object
   * @returns Promise
   */
  updateBrandRequirement({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.brand_requirements.updateBrandRequirement+`${data._id}`, data)
        .then((res) => {
          if (res.data.success) {
            commit("updateBrandRequirement", data);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * @description Remove Brand Requirement
   * @param {Number} id - Brand Requirement Id
   * @returns Promise
   */
  removeBrandRequirementByID({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(apiPath.brand_requirements.removeBrandRequirement+`${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("removeBrandRequirement", id);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description fetch Brand Requirement by Brand ID
   * @param {Number} id - Brand Id
   * @returns Promise
   */
  fetchBrandRequirementByBrandID({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let id = options.id;
      let url = getQueryString(params).substring(1);
      axios
        .get(apiPath.brand_requirements.getAllBrandRequirementsByBrandId+`${id}?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description fetch all Matching properties
   * @param {Number} id - Options
   * @returns Promise
   */
  async getAllMatchingProperties({ commit, rootState }, data) {
    try {
      let { id, page, per_page, pincode, min, max } = data;
      let columnValues = data.selectedOpt;
      let columns = columnValues.join(",");

      let params = {
        page: page,
        per_page: per_page,
        pincode: pincode,
        columns: columns,
        max: max,
      };
      if (min == 0) {
        params.min = "0";
      } else {
        params.min = min;
      }
      let url = getQueryString(params).substring(1);

      // Use axios directly for the API request
      let val = await axios.get(
        apiPath.brand_requirements.getAllMatchingProperties+`${id}?${url}`,
        {
          headers: {
            Authorization: `Bearer ${rootState.authStore.token}`,
          },
        }
      );

      if (val.data.success) {
        commit("setAllMatchingProperties", val.data.data);
        return val;
      }
    } catch (e) {
      return e;
    }
  },

  /**
   * @description Create New Matching property
   * @param {Object} data - Matching property Object
   * @returns Promise
   */
  createMatchingProperty({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.brand_requirements.createMatchingProperties, data)
        .then(async (res) => {
          if (res.data.success) {
            await commit("addNewMatchingProperty", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Filter Brand Requirements
   * @param {Number} id - pincode , state, area name , brandId
   * @returns Promise
   */
  async filterBrandrequirements({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = {
          ...options,
          brand_name: state.filterValue.brand_name,
          city: state.filterValue.city,
          req_type: state.filterValue.req_type,
          priorities: state.filterValue.priorities,
          timeline: state.filterValue.timeline,
          country: state.filterValue.country,
          pincode: state.filterValue.pincode,
          state: state.filterValue.state,
        };
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
          brand_name: state.filterValue.brand_name,
          city: state.filterValue.city,
          req_type: state.filterValue.req_type,
          priorities: state.filterValue.priorities,
          timelines: state.filterValue.timeline,
          country: state.filterValue.country,
          pincode: state.filterValue.pincode,
          state: state.filterValue.state,
        };
      }
      let id = options?.id;
      let url = getQueryString(params).substring(1);
      let urlString = id ? apiPath.brand_requirements.filterBrandRequirements+`${id}?${url}` : apiPath.brand_requirements.filterBrandRequirements+`?${url}`
      axios
        .get(urlString)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllBrandRequirement", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description On download button get data in excel sheet
   * @returns Promise
   */
  async getAllExcelSheetByDownload({ commit }) {
    const params = {
        brandName: state.filterValue.brand_name,
        country: state.filterValue.country,
        state: state.filterValue.state,
        city: state.filterValue.city,
        pincode: state.filterValue.pincode,
        req_type: state.filterValue.req_type,
        priorities: state.filterValue.priorities,
        timelines: state.filterValue.timeline,
    };
  let url = getQueryString(params).substring(1);
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiPath.brand_requirements.downloadBrandRequirements}?${url}`)
        .then((res) => {
          resolve(res);
          commit("doNothing");
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
   /**
   * @description Get add Brand Requirement template
   * @returns Promise
   */
    getBulkBrandTemplate({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(apiPath.brand_requirements.bulkBrandTemplate)
          .then((res) => {
            if (res.data.success) {
              commit("setTemplate", res.data.data);
              resolve(res);
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    /**
   * @description Get bulk Brand Requirement upload
   * @returns Promise
   */
     // eslint-disable-next-line no-empty-pattern
     async bulkBrandRequirementUploadFile({  }, data) {
      const formData = new FormData();
      formData.append("file", data.file);
      formData.append("app_type", data.app_type);
      formData.append("brandId", data.brandId);
      return new Promise((resolve, reject) => {
        axios
          .post(apiPath.brand_requirements.bulkBrandUploadFile, formData)
          .then((res) => {
            if (res.data.status) {
              resolve(res);
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
