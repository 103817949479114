/**
 * @description filter updated data
 * @param {Object} data - originalObject, updatedObject
 * @returns changedData
 */
export const filterUpdatedObject = (originalObject, updatedObject) => {
  const changedData = {};
  for (let i in updatedObject) {
    if (updatedObject[i] !== originalObject[i]) {
      changedData[i] = updatedObject[i];
    }
  }
  changedData.id = originalObject.id;
  return changedData;
};

/**
 * @description get date in a systematic order
 * @param {Object} data - unstructure value
 * @returns date
 */

export const getdate = (d) => {
  let x = new Date(d);
  let y = x.toString().substring(0, 15);
  let z = x.toLocaleTimeString("en-IN");
  return y + " | " + z;
};

/**
 * @description filter data
 * @param {Object} data - remove unwanted data
 * @returns date
 */
export const getQueryString = (queryObject) => {
  return Object.entries(queryObject)
    .filter((filter) => {
      return filter[1];
    })
    .reduce((qString, filterField) => {
      return qString.concat(
        "&" +
          filterField[0]
            .split(/(?=[A-Z])/)
            .join("_")
            .toLowerCase() +
          "=" +
          filterField[1]
      );
    }, "");
};

export const getQueryStringWithSameKeys = (queryObject) => {
  return Object.entries(queryObject)
    .filter((filter) => {
      return filter[1];
    })
    .reduce((qString, filterField) => {
      return qString.concat(
        "&" + filterField[0] + "=" + filterField[1]
      );
    }, "");
};

/**
 * @description genrate floor names from given array
 * @param {Object} data - array of floors
 * @returns array
 */
export const getFloorNames = (data) => {
  const result = [];
  let array = [];
  if (Array.isArray(data)) {
    array = data;
  } else {
    for (let i = 0; i < data; i++) {
      array.push(i);
    }
  }
  array.forEach((value) => {
    let suffix = "th";
    if (value == 0) {
      result.push({ name: "Ground", value: "0" });
      return;
    } else if (value % 10 === 1 && value % 100 !== 11) {
      suffix = "st";
    } else if (value % 10 === 2 && value % 100 !== 12) {
      suffix = "nd";
    } else if (value % 10 === 3 && value % 100 !== 13) {
      suffix = "rd";
    }
    result.push({ name: value + suffix, value: `${value}` });
  });
  return result;
};
